import * as _froalaEditor2 from "froala-editor";
var _froalaEditor = _froalaEditor2;
try {
  if ("default" in _froalaEditor2) _froalaEditor = _froalaEditor2.default;
} catch (e) {}
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
/*!
 * froala_editor v4.1.4 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2023 Froala Labs
 */

!function (e, t) {
  t(_froalaEditor);
}(exports, function (y) {
  "use strict";

  y = y && y.hasOwnProperty("default") ? y["default"] : y, Object.assign(y.POPUP_TEMPLATES, {
    "link.edit": "[_BUTTONS_]",
    "link.insert": "[_BUTTONS_][_INPUT_LAYER_]"
  }), Object.assign(y.DEFAULTS, {
    linkEditButtons: ["linkOpen", "linkStyle", "linkEdit", "linkRemove"],
    linkInsertButtons: ["linkBack", "|", "linkList"],
    linkAttributes: {},
    linkAutoPrefix: "http://",
    linkStyles: {
      "fr-green": "Green",
      "fr-strong": "Thick"
    },
    linkMultipleStyles: !0,
    linkConvertEmailAddress: !0,
    linkAlwaysBlank: !1,
    linkAlwaysNoFollow: !1,
    linkNoOpener: !0,
    linkNoReferrer: !0,
    linkList: [{
      text: "Froala",
      href: "https://froala.com",
      target: "_blank"
    }, {
      text: "Google",
      href: "https://google.com",
      target: "_blank"
    }, {
      displayText: "Facebook",
      href: "https://facebook.com"
    }],
    linkText: !0
  }), y.PLUGINS.link = function (h) {
    var m = h.$;
    function v() {
      var e = h.image ? h.image.get() : null;
      if (e || !h.$wp) return "A" == h.el.tagName ? h.el : e && e.get(0).parentNode && "A" == e.get(0).parentNode.tagName ? e.get(0).parentNode : void 0;
      var t = h.selection.ranges(0).commonAncestorContainer;
      try {
        t && (t.contains && t.contains(h.el) || !h.el.contains(t) || h.el == t) && (t = null);
      } catch (l) {
        t = null;
      }
      if (t && "A" === t.tagName) return t;
      var n,
        i,
        r = h.selection.element(),
        a = h.selection.endElement();
      "A" == r.tagName || h.node.isElement(r) || (r = h.opts.iframe && null !== (n = h.markdown) && void 0 !== n && n.isEnabled ? null : m(r).parentsUntil(h.$el, "a").first().get(0));
      "A" == a.tagName || h.node.isElement(a) || (a = h.opts.iframe && null !== (i = h.markdown) && void 0 !== i && i.isEnabled ? null : m(a).parentsUntil(h.$el, "a").first().get(0));
      try {
        a && (a.contains && a.contains(h.el) || !h.el.contains(a) || h.el == a) && (a = null);
      } catch (l) {
        a = null;
      }
      try {
        r && (r.contains && r.contains(h.el) || !h.el.contains(r) || h.el == r) && (r = null);
      } catch (l) {
        r = null;
      }
      return a && a == r && "A" == a.tagName ? (h.browser.msie || h.helpers.isMobile()) && (h.selection.info(r).atEnd || h.selection.info(r).atStart) ? null : r : null;
    }
    function b() {
      var e,
        t,
        n,
        i,
        r = h.image ? h.image.get() : null,
        a = [];
      if (r) "A" == r.get(0).parentNode.tagName && a.push(r.get(0).parentNode);else if (h.win.getSelection) {
        var l = h.win.getSelection();
        if (l.getRangeAt && l.rangeCount) {
          i = h.doc.createRange();
          for (var s = 0; s < l.rangeCount; ++s) if ((t = (e = l.getRangeAt(s)).commonAncestorContainer) && 1 != t.nodeType && (t = t.parentNode), t && "a" == t.nodeName.toLowerCase()) a.push(t);else {
            n = t.getElementsByTagName("a");
            for (var o = 0; o < n.length; ++o) i.selectNodeContents(n[o]), i.compareBoundaryPoints(e.END_TO_START, e) < 1 && -1 < i.compareBoundaryPoints(e.START_TO_END, e) && a.push(n[o]);
          }
        }
      } else if (h.doc.selection && "Control" != h.doc.selection.type) if ("a" == (t = (e = h.doc.selection.createRange()).parentElement()).nodeName.toLowerCase()) a.push(t);else {
        n = t.getElementsByTagName("a"), i = h.doc.body.createTextRange();
        for (var p = 0; p < n.length; ++p) i.moveToElementText(n[p]), -1 < i.compareEndPoints("StartToEnd", e) && i.compareEndPoints("EndToStart", e) < 1 && a.push(n[p]);
      }
      return a;
    }
    function E(r) {
      if (h.core.hasFocus() || h.opts.iframe && (!document.hasFocus || document.hasFocus())) {
        if (a(), r && "keyup" === r.type && (r.altKey || r.which == y.KEYCODE.ALT)) return !0;
        setTimeout(function () {
          if (!r || r && (1 == r.which || "mouseup" != r.type)) {
            var e = v(),
              t = h.image ? h.image.get() : null;
            if (e && !t) {
              if (h.image) {
                var n = h.node.contents(e);
                if (1 == n.length && "IMG" == n[0].tagName) {
                  var i = h.selection.ranges(0);
                  return 0 === i.startOffset && 0 === i.endOffset ? m(e).before(y.INVISIBLE_SPACE + y.MARKERS) : m(e).after(y.INVISIBLE_SPACE + y.MARKERS), h.selection.restore(), !1;
                }
              }
              r && r.stopPropagation(), l(e);
            }
          }
        }, h.helpers.isIOS() ? 100 : 0);
      }
    }
    function l(e) {
      var t = h.popups.get("link.edit");
      t || (t = function a() {
        var e = "";
        1 <= h.opts.linkEditButtons.length && ("A" == h.el.tagName && 0 <= h.opts.linkEditButtons.indexOf("linkRemove") && h.opts.linkEditButtons.splice(h.opts.linkEditButtons.indexOf("linkRemove"), 1), e = "<div class=\"fr-buttons\">".concat(h.button.buildList(h.opts.linkEditButtons), "</div>"));
        var t = {
            buttons: e
          },
          n = h.popups.create("link.edit", t);
        h.$wp && h.events.$on(h.$wp, "scroll.link-edit", function () {
          v() && h.popups.isVisible("link.edit") && l(v());
        });
        return n;
      }());
      var n = m(e);
      h.popups.isVisible("link.edit") || h.popups.refresh("link.edit"), h.popups.setContainer("link.edit", h.$sc);
      var i = n.offset().left + n.outerWidth() / 2,
        r = n.offset().top + n.outerHeight();
      h.popups.show("link.edit", i, r, n.outerHeight(), !0);
    }
    function a() {
      h.popups.hide("link.edit");
    }
    function o() {
      var e = h.popups.get("link.insert"),
        t = v();
      if (t) {
        var n,
          i,
          r = m(t),
          a = e.find("input.fr-link-attr[type=\"text\"]"),
          l = e.find("input.fr-link-attr[type=\"checkbox\"]");
        for (n = 0; n < a.length; n++) (i = m(a[n])).val(r.attr(i.attr("name") || ""));
        for (l.attr("checked", !1), n = 0; n < l.length; n++) i = m(l[n]), r.attr(i.attr("name")) == i.data("checked") && i.attr("checked", !0);
        e.find("input.fr-link-attr[type=\"text\"][name=\"text\"]").val(r.text());
      } else e.find("input.fr-link-attr[type=\"text\"]").val(""), e.find("input.fr-link-attr[type=\"checkbox\"]").attr("checked", !1), e.find("input.fr-link-attr[type=\"text\"][name=\"text\"]").val(h.selection.text());
      e.find("input.fr-link-attr").trigger("change"), (h.image ? h.image.get() : null) ? e.find(".fr-link-attr[name=\"text\"]").parent().hide() : e.find(".fr-link-attr[name=\"text\"]").parent().show();
    }
    function s(e) {
      if (e) return h.popups.onRefresh("link.insert", o), !0;
      var t = "";
      1 <= h.opts.linkInsertButtons.length && (t = "<div class=\"fr-buttons fr-tabs\">".concat(h.button.buildList(h.opts.linkInsertButtons), "</div>"));
      var n = "",
        i = 0;
      for (var r in n = "<div class=\"fr-link-insert-layer fr-layer fr-active\" id=\"fr-link-insert-layer-".concat(h.id, "\">"), n += "<div class=\"fr-input-line\"><input id=\"fr-link-insert-layer-url-".concat(h.id, "\" name=\"href\" type=\"text\" class=\"fr-link-attr\" placeholder=\"").concat(h.language.translate("URL"), "\" tabIndex=\"").concat(++i, "\"></div>"), h.opts.linkText && (n += "<div class=\"fr-input-line\"><input id=\"fr-link-insert-layer-text-".concat(h.id, "\" name=\"text\" type=\"text\" class=\"fr-link-attr\" placeholder=\"").concat(h.language.translate("Text"), "\" tabIndex=\"").concat(++i, "\"></div>")), h.opts.linkAttributes) if (h.opts.linkAttributes.hasOwnProperty(r)) {
        var a = h.opts.linkAttributes[r];
        n += "<div class=\"fr-input-line\"><input name=\"".concat(r, "\" type=\"text\" class=\"fr-link-attr\" placeholder=\"").concat(h.language.translate(a), "\" tabIndex=\"").concat(++i, "\"></div>");
      }
      h.opts.linkAlwaysBlank || (n += "<div class=\"fr-checkbox-line\"><span class=\"fr-checkbox\"><input name=\"target\" class=\"fr-link-attr\" data-checked=\"_blank\" type=\"checkbox\" id=\"fr-link-target-".concat(h.id, "\" tabIndex=\"").concat(++i, "\"><span>").concat("<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"10\" height=\"10\" viewBox=\"0 0 32 32\"><path d=\"M27 4l-15 15-7-7-5 5 12 12 20-20z\" fill=\"#FFF\"></path></svg>", "</span></span><label id=\"fr-label-target-").concat(h.id, "\">").concat(h.language.translate("Open in new tab"), "</label></div>"));
      var l = {
          buttons: t,
          input_layer: n += "<div class=\"fr-action-buttons\"><button class=\"fr-command fr-submit\" role=\"button\" data-cmd=\"linkInsert\" href=\"#\" tabIndex=\"".concat(++i, "\" type=\"button\">").concat(h.language.translate("Insert"), "</button></div></div>")
        },
        s = h.popups.create("link.insert", l);
      return h.$wp && h.events.$on(h.$wp, "scroll.link-insert", function () {
        (h.image ? h.image.get() : null) && h.popups.isVisible("link.insert") && f(), h.popups.isVisible("link.insert") && c();
      }), s;
    }
    function p(e, t, n) {
      if (h.opts.trackChangesEnabled) {
        if (h.edit.on(), h.events.focus(!0), h.undo.saveStep(), h.markers.insert(), h.html.wrap(), !h.$el.find(".fr-marker").length) return void h.popups.hide("link.insert");
        h.markers.remove();
      }
      if (void 0 === n && (n = {}), !1 === h.events.trigger("link.beforeInsert", [e, t, n])) return !1;
      var i = h.image ? h.image.get() : null;
      i || "A" == h.el.tagName ? "A" == h.el.tagName && h.$el.focus() : (h.selection.restore(), h.popups.hide("link.insert"));
      var r = e;
      h.opts.linkConvertEmailAddress && h.helpers.isEmail(e) && !/^mailto:.*/i.test(e) && (e = "mailto:".concat(e));
      if ("" === h.opts.linkAutoPrefix || new RegExp("^(" + y.LinkProtocols.join("|") + "):.", "i").test(e) || /^data:image.*/i.test(e) || /^(https?:|ftps?:|file:|)\/\//i.test(e) || /^([A-Za-z]:(\\){1,2}|[A-Za-z]:((\\){1,2}[^\\]+)+)(\\)?$/i.test(e) || ["/", "{", "[", "#", "(", ".", "\"", "\\"].indexOf((e || "")[0]) < 0 && (e = h.opts.linkAutoPrefix + e), e = h.helpers.sanitizeURL(e), h.opts.linkAlwaysBlank && (n.target = "_blank"), h.opts.linkAlwaysNoFollow && (n.rel = "nofollow"), h.helpers.isEmail(r) && (n.target = null, n.rel = null), "_blank" == n.target ? (h.opts.linkNoOpener && (n.rel ? n.rel += " noopener" : n.rel = "noopener"), h.opts.linkNoReferrer && (n.rel ? n.rel += " noreferrer" : n.rel = "noreferrer")) : null == n.target && (n.rel ? n.rel = n.rel.replace(/noopener/, "").replace(/noreferrer/, "") : n.rel = null), t = t || "", e === h.opts.linkAutoPrefix) return h.popups.get("link.insert").find("input[name=\"href\"]").addClass("fr-error"), h.events.trigger("link.bad", [r]), !1;
      var a,
        l = v();
      if (l) {
        if ((a = m(l)).attr("href", e), 0 < t.length && a.text() != t && !i) {
          if (h.opts.trackChangesEnabled) {
            var s = m(a.get(0).outerHTML);
            s.insertBefore(a.parent());
            var o = h.track_changes.wrapLinkInTracking(s, h.track_changes.getPendingChanges().length - 1),
              p = h.track_changes.wrapInDelete(o);
            a.parent().append(p);
          }
          for (var c = a.get(0); 1 === c.childNodes.length && c.childNodes[0].nodeType == Node.ELEMENT_NODE;) c = c.childNodes[0];
          m(c).text(t);
        }
        for (var f in i || a.prepend(y.START_MARKER).append(y.END_MARKER), n) n[f] ? a.attr(f, n[f]) : a.removeAttr(f);
        i || h.selection.restore();
      } else {
        i ? (i.wrap("<a href=\"".concat(e, "\"></a>")), h.image.hasCaption() && i.parent().append(i.parents(".fr-img-caption").find(".fr-inner"))) : (h.format.remove("a"), h.selection.isCollapsed() ? (t = 0 === t.length ? r : t, h.html.insert("<a href=\"".concat(e, "\">").concat(y.START_MARKER).concat(t.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")).concat(y.END_MARKER, "</a>")), h.selection.restore()) : 0 < t.length && t != h.selection.text().replace(/\n/g, "") ? (h.selection.remove(), h.html.insert("<a href=\"".concat(e, "\">").concat(y.START_MARKER).concat(t.replace(/&/g, "&amp;")).concat(y.END_MARKER, "</a>")), h.selection.restore()) : (!function g() {
          if (!h.selection.isCollapsed()) {
            h.selection.save();
            for (var e = h.$el.find(".fr-marker").addClass("fr-unprocessed").toArray(); e.length;) {
              var t = m(e.pop());
              t.removeClass("fr-unprocessed");
              var n = h.node.deepestParent(t.get(0));
              if (n) {
                for (var i = t.get(0), r = "", a = ""; i = i.parentNode, h.node.isBlock(i) || (r += h.node.closeTagString(i), a = h.node.openTagString(i) + a), i != n;);
                var l = h.node.openTagString(t.get(0)) + t.html() + h.node.closeTagString(t.get(0));
                t.replaceWith("<span id=\"fr-break\"></span>");
                var s = n.outerHTML;
                s = (s = s.replace(/<span id="fr-break"><\/span>/g, r + l + a)).replace(a + r, ""), n.outerHTML = s;
              }
              e = h.$el.find(".fr-marker.fr-unprocessed").toArray();
            }
            h.html.cleanEmptyTags(), h.selection.restore();
          }
        }(), h.format.apply("a", {
          href: e
        })));
        for (var d = b(), u = 0; u < d.length; u++) (a = m(d[u])).attr(n), a.removeAttr("_moz_dirty");
        1 == d.length && h.$wp && !i && (m(d[0]).prepend(y.START_MARKER).append(y.END_MARKER), h.selection.restore());
      }
      if (i) {
        var k = h.popups.get("link.insert");
        k && k.find("input:focus").blur(), h.image.edit(i);
      } else E();
    }
    function c() {
      a();
      var e = v();
      if (e) {
        var t = h.popups.get("link.insert");
        t || (t = s()), h.popups.isVisible("link.insert") || (h.popups.refresh("link.insert"), h.selection.save(), h.helpers.isMobile() && (h.events.disableBlur(), h.$el.blur(), h.events.enableBlur())), h.popups.setContainer("link.insert", h.$sc);
        var n = (h.image ? h.image.get() : null) || m(e),
          i = n.offset().left + n.outerWidth() / 2,
          r = n.offset().top + n.outerHeight();
        h.popups.show("link.insert", i, r, n.outerHeight(), !0);
      }
    }
    function f() {
      var e = h.image ? h.image.getEl() : null;
      if (e) {
        var t = h.popups.get("link.insert"),
          n = e.outerWidth() / 2;
        h.image.hasCaption() && (e = e.find(".fr-img-wrap"), m(e) && m(e).find("img") && (n = m(e).find("img").outerWidth() / 2)), t || (t = s()), o(), h.popups.setContainer("link.insert", h.$sc);
        var i = e.offset().left + n,
          r = e.offset().top + e.outerHeight(),
          a = e.outerHeight();
        h.opts.iframe && h.image.hasCaption() && m(e) && m(e).find("img") && (a = m(e).find("img").outerHeight()), h.popups.show("link.insert", i, r, a, !0);
      }
    }
    return {
      _init: function e() {
        h.events.on("keyup", function (e) {
          e.which != y.KEYCODE.ESC && E(e);
        }), h.events.on("window.mouseup", E), h.events.$on(h.$el, "click", "a", function (e) {
          h.edit.isDisabled() && e.preventDefault();
        }), h.helpers.isMobile() && h.events.$on(h.$doc, "selectionchange", E), s(!0), "A" == h.el.tagName && h.$el.addClass("fr-view"), h.events.on("toolbar.esc", function () {
          if (h.popups.isVisible("link.edit")) return h.events.disableBlur(), h.events.focus(), !1;
        }, !0);
      },
      remove: function i() {
        var e = v(),
          t = h.image ? h.image.get() : null;
        if (!1 === h.events.trigger("link.beforeRemove", [e])) return !1;
        if (t && e) {
          if (h.image.hasCaption()) {
            t.addClass("img-link-caption"), m(e).replaceWith(m(e).html());
            var n = document.querySelectorAll("img.img-link-caption");
            h.image.edit(m(n[0])), m(n[0]).removeClass("img-link-caption");
          } else t.unwrap(), h.image.edit(t);
        } else e && (h.selection.save(), m(e).replaceWith(m(e).html()), h.selection.restore(), a());
      },
      showInsertPopup: function d() {
        var e = h.$tb.find(".fr-command[data-cmd=\"insertLink\"]"),
          t = h.popups.get("link.insert");
        if (t || (t = s()), !t.hasClass("fr-active")) if (h.popups.refresh("link.insert"), h.popups.setContainer("link.insert", h.$tb || h.$sc), e.isVisible()) {
          var n = h.button.getPosition(e),
            i = n.left,
            r = n.top;
          h.popups.show("link.insert", i, r, e.outerHeight());
        } else h.position.forSelection(t), h.popups.show("link.insert");
      },
      usePredefined: function u(e) {
        var t,
          n,
          i = h.opts.linkList[e],
          r = h.popups.get("link.insert"),
          a = r.find("input.fr-link-attr[type=\"text\"]"),
          l = r.find("input.fr-link-attr[type=\"checkbox\"]");
        for (i.rel && (r.rel = i.rel), n = 0; n < a.length; n++) i[(t = m(a[n])).attr("name")] ? (t.val(i[t.attr("name")]), t.toggleClass("fr-not-empty", !0)) : "text" != t.attr("name") && t.val("");
        for (n = 0; n < l.length; n++) (t = m(l[n])).attr("checked", t.data("checked") == i[t.attr("name")]);
        h.accessibility.focusPopup(r);
      },
      insertCallback: function k() {
        var e,
          t,
          n = h.popups.get("link.insert"),
          i = n.find("input.fr-link-attr[type=\"text\"]"),
          r = n.find("input.fr-link-attr[type=\"checkbox\"]"),
          a = (i.filter("[name=\"href\"]").val() || "").trim(),
          l = h.opts.linkText ? i.filter("[name=\"text\"]").val() : "",
          s = {};
        for (t = 0; t < i.length; t++) e = m(i[t]), ["href", "text"].indexOf(e.attr("name")) < 0 && (s[e.attr("name")] = e.val());
        for (t = 0; t < r.length; t++) (e = m(r[t])).is(":checked") ? s[e.attr("name")] = e.data("checked") : s[e.attr("name")] = e.data("unchecked") || null;
        n.rel && (s.rel = n.rel);
        var o = h.helpers.scrollTop();
        p(a, l, s), m(h.o_win).scrollTop(o);
      },
      insert: p,
      update: c,
      get: v,
      allSelected: b,
      back: function t() {
        h.image && h.image.get() ? h.image.back() : (h.events.disableBlur(), h.selection.restore(), h.events.enableBlur(), v() && h.$wp ? (h.selection.restore(), a(), E()) : "A" == h.el.tagName ? (h.$el.focus(), E()) : (h.popups.hide("link.insert"), h.toolbar.showInline()));
      },
      imageLink: f,
      applyStyle: function g(e, t, n) {
        void 0 === n && (n = h.opts.linkMultipleStyles), void 0 === t && (t = h.opts.linkStyles);
        var i = v();
        if (!i) return !1;
        if (!n) {
          var r = Object.keys(t);
          r.splice(r.indexOf(e), 1), m(i).removeClass(r.join(" "));
        }
        m(i).toggleClass(e), E();
      }
    };
  }, y.DefineIcon("insertLink", {
    NAME: "link",
    SVG_KEY: "insertLink"
  }), y.RegisterShortcut(y.KEYCODE.K, "insertLink", null, "K"), y.RegisterCommand("insertLink", {
    title: "Insert Link",
    undo: !1,
    focus: !0,
    refreshOnCallback: !1,
    popup: !0,
    callback: function () {
      (this || _global).popups.isVisible("link.insert") ? ((this || _global).$el.find(".fr-marker").length && ((this || _global).events.disableBlur(), (this || _global).selection.restore()), (this || _global).popups.hide("link.insert")) : (this || _global).link.showInsertPopup();
    },
    plugin: "link"
  }), y.DefineIcon("linkOpen", {
    NAME: "external-link",
    FA5NAME: "external-link-alt",
    SVG_KEY: "openLink"
  }), y.RegisterCommand("linkOpen", {
    title: "Open Link",
    undo: !1,
    refresh: function (e) {
      (this || _global).link.get() ? e.removeClass("fr-hidden") : e.addClass("fr-hidden");
    },
    callback: function () {
      var e = (this || _global).link.get();
      e && (-1 !== e.href.indexOf("mailto:") ? (this || _global).o_win.open(e.href).close() : (e.target || (e.target = "_self"), (this || _global).browser.msie || (this || _global).browser.edge ? (this || _global).o_win.open(e.href, e.target) : (this || _global).o_win.open(e.href, e.target, "noopener")), (this || _global).popups.hide("link.edit"));
    },
    plugin: "link"
  }), y.DefineIcon("linkEdit", {
    NAME: "edit",
    SVG_KEY: "edit"
  }), y.RegisterCommand("linkEdit", {
    title: "Edit Link",
    undo: !1,
    refreshAfterCallback: !1,
    popup: !0,
    callback: function () {
      (this || _global).link.update();
    },
    refresh: function (e) {
      (this || _global).link.get() ? e.removeClass("fr-hidden") : e.addClass("fr-hidden");
    },
    plugin: "link"
  }), y.DefineIcon("linkRemove", {
    NAME: "unlink",
    SVG_KEY: "unlink"
  }), y.RegisterCommand("linkRemove", {
    title: "Unlink",
    callback: function () {
      (this || _global).link.remove();
    },
    refresh: function (e) {
      (this || _global).link.get() ? e.removeClass("fr-hidden") : e.addClass("fr-hidden");
    },
    plugin: "link"
  }), y.DefineIcon("linkBack", {
    NAME: "arrow-left",
    SVG_KEY: "back"
  }), y.RegisterCommand("linkBack", {
    title: "Back",
    undo: !1,
    focus: !1,
    back: !0,
    refreshAfterCallback: !1,
    callback: function () {
      (this || _global).link.back();
    },
    refresh: function (e) {
      var t = (this || _global).link.get() && (this || _global).doc.hasFocus();
      ((this || _global).image ? (this || _global).image.get() : null) || t || (this || _global).opts.toolbarInline ? (e.removeClass("fr-hidden"), e.next(".fr-separator").removeClass("fr-hidden")) : (e.addClass("fr-hidden"), e.next(".fr-separator").addClass("fr-hidden"));
    },
    plugin: "link"
  }), y.DefineIcon("linkList", {
    NAME: "search",
    SVG_KEY: "search"
  }), y.RegisterCommand("linkList", {
    title: "Choose Link",
    type: "dropdown",
    focus: !1,
    undo: !1,
    refreshAfterCallback: !1,
    html: function () {
      for (var e = "<ul class=\"fr-dropdown-list\" role=\"presentation\">", t = (this || _global).opts.linkList, n = 0; n < t.length; n++) e += "<li role=\"presentation\"><a class=\"fr-command\" tabIndex=\"-1\" role=\"option\" data-cmd=\"linkList\" data-param1=\"".concat(n, "\">").concat(t[n].displayText || t[n].text, "</a></li>");
      return e += "</ul>";
    },
    callback: function (e, t) {
      (this || _global).link.usePredefined(t);
    },
    plugin: "link"
  }), y.RegisterCommand("linkInsert", {
    focus: !1,
    refreshAfterCallback: !1,
    callback: function () {
      (this || _global).link.insertCallback();
    },
    refresh: function (e) {
      (this || _global).link.get() ? e.text((this || _global).language.translate("Update")) : e.text((this || _global).language.translate("Insert"));
    },
    plugin: "link"
  }), y.DefineIcon("imageLink", {
    NAME: "link",
    SVG_KEY: "insertLink"
  }), y.RegisterCommand("imageLink", {
    title: "Insert Link",
    undo: !1,
    focus: !1,
    popup: !0,
    callback: function () {
      (this || _global).link.imageLink();
    },
    refresh: function (e) {
      var t;
      (this || _global).link.get() ? ((t = e.prev()).hasClass("fr-separator") && t.removeClass("fr-hidden"), e.addClass("fr-hidden")) : ((t = e.prev()).hasClass("fr-separator") && t.addClass("fr-hidden"), e.removeClass("fr-hidden"));
    },
    plugin: "link"
  }), y.DefineIcon("linkStyle", {
    NAME: "magic",
    SVG_KEY: "linkStyles"
  }), y.RegisterCommand("linkStyle", {
    title: "Style",
    type: "dropdown",
    html: function () {
      var e = "<ul class=\"fr-dropdown-list\" role=\"presentation\">",
        t = (this || _global).opts.linkStyles;
      for (var n in t) t.hasOwnProperty(n) && (e += "<li role=\"presentation\"><a class=\"fr-command\" tabIndex=\"-1\" role=\"option\" data-cmd=\"linkStyle\" data-param1=\"".concat(n, "\">").concat((this || _global).language.translate(t[n]), "</a></li>"));
      return e += "</ul>";
    },
    callback: function (e, t) {
      (this || _global).link.applyStyle(t);
    },
    refreshOnShow: function (e, t) {
      var n = (this || _global).$,
        i = (this || _global).link.get();
      if (i) {
        var r = n(i);
        t.find(".fr-command").each(function () {
          var e = n(this || _global).data("param1"),
            t = r.hasClass(e);
          n(this || _global).toggleClass("fr-active", t).attr("aria-selected", t);
        });
      }
    },
    refresh: function (e) {
      (this || _global).link.get() ? e.removeClass("fr-hidden") : e.addClass("fr-hidden");
    },
    plugin: "link"
  });
});
export default exports;